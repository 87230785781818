// initial state
const state = () => ({
    org: null
})

const getters = {
    Org: (state) => {
        return state.org
    }
}

const actions = {
    
}

// update methods
const mutations = {
    SetOrg (state, org) {
        state.org = org
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
  