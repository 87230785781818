/* eslint-disable */
// import Vue from 'vue'
// import Vuex from 'vuex'
import user from './modules/user'
import profiles from './modules/profiles'
import prefecture from './modules/prefecture'
import org from './modules/org'
import stats from './modules/stats'

//Vue.use(Vuex)

Vue.config.devtools = process.env.NODE_ENV === 'development'
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    user,
    profiles,
    prefecture,
    org,
    stats
  },
  strict: debug,
  plugins: [] //debug ? [createLogger()] : []
})