<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item :link="{ name: '首页', icon: 'el-icon-house text-muted', path: '/dashboard' }">
        </sidebar-item>
        <sidebar-item :link="{ name: '账号管理', icon: 'el-icon-user text-muted', path: '/users' }" v-if="isSuper && hasMUS">
        </sidebar-item>
        <!-- 
        <sidebar-item :link="{ name: '年度计划表', icon: 'ni ni-paper-diploma text-muted', path: '/plans' }" v-if="false">
        </sidebar-item>
        <sidebar-item :link="{ name: '数据填报', icon: 'ni ni-shop text-muted' }" v-if="!isReportReader && !deny3Z">
          <sidebar-item :link="{ name: '线上专馆', path: '/onlinestores' }" v-if="false"/>
          <sidebar-item :link="{ name: '企业', path: '/stores' }" />
          <sidebar-item :link="{ name: '专区', path: '/areas' }" v-if="false"/>
          <sidebar-item :link="{ name: '专柜', path: '/counters' }" v-if="false"/>
        </sidebar-item>
        <sidebar-item :link="{ name: '数据统计', icon: 'ni ni-chart-bar-32 text-muted' }" v-if="!deny3Z">
          <sidebar-item :link="{ name: '线上专馆', path: '/onlinestoresreport' }" v-if="false"/>
          <sidebar-item :link="{ name: '企业', path: '/storesreport' }" />
          <sidebar-item :link="{ name: '专区', path: '/areasreport' }" v-if="false"/>
          <sidebar-item :link="{ name: '专柜', path: '/countersreport' }" v-if="false"/>
          <sidebar-item :link="{ name: '三专统计', path: '/totalreport' }" v-if="false" />
        </sidebar-item>
        <sidebar-item :link="{ name: '送菜上门服务名单', icon: 'ni ni-delivery-fast text-muted' }" v-if="hasVSC">
          <sidebar-item :link="{ name: '扶贫干部', path: '/songcai/扶贫干部' }" :key="'fupinganbu'" />
          <sidebar-item :link="{ name: '援港医务人员', path: '/songcai/援港医务人员' }" :key="'yuanganyiwurenyuan'" />
        </sidebar-item>
         -->
        <sidebar-item :link="{ name: '统计表', icon: 'el-icon-document-copy text-muted', path: '/datainputs' }" v-if="!isSuper">
        </sidebar-item>
        <sidebar-item :link="{ name: '审核', icon: 'el-icon-s-check text-muted', total: totalAuditionCount }" v-if="isSuper">
          <sidebar-item :link="{ name: '单位注册审核', path: '/orgsaudition', total: this.$store.state.stats.totalOrgs }" />
          <sidebar-item :link="{ name: '单位资料修改审核', path: '/orgupdateaudition', total: this.$store.state.stats.totalOrgUpdates }" />
          <sidebar-item :link="{ name: '统计表审核', path: '/dataaudition', total: this.$store.state.stats.totalData }" />
        </sidebar-item>
        <sidebar-item :link="{ name: '报表', icon: 'el-icon-tickets text-muted' }" v-if="isSuper">
          <sidebar-item :link="{ name: '采购数据汇总表', path: '/datareport/0' }" />
          <sidebar-item :link="{ name: '销售数据汇总表', path: '/datareport/1' }" />
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  //import axios from 'axios';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        
      }
    },
    computed: {
      loginedUser() {
        return this.$store.state.user.user
      },
      totalAuditionCount() {
        return this.$store.state.stats.totalData + this.$store.state.stats.totalOrgs + this.$store.state.stats.totalOrgUpdates
      }
    },
    beforeRouteEnter (to, from, next) {
      //console.log(`to=${to.name} & from=${from.name}`)
      if(!from.name) {
        axios
          .get("getsession")
          .then((res) => {
            if (res.data.errcode == 0) {
              if (to.name != '账号状态' && res.data.org && res.data.org.status != 1) {
                next(vm => {
                  vm.$store.commit("user/SetUser", res.data.user)
                  vm.$store.commit("profiles/SetProfiles", res.data.profiles);
                  vm.$store.commit("prefecture/SetPrefecture", res.data.prefecture);
                  vm.$store.commit("org/SetOrg", res.data.org);
                  vm.$router.push("/accountstatus")
                })
              } else {
                next(vm => {
                  vm.$store.commit("user/SetUser", res.data.user)
                  vm.$store.commit("profiles/SetProfiles", res.data.profiles);
                  vm.$store.commit("prefecture/SetPrefecture", res.data.prefecture);
                  vm.$store.commit("org/SetOrg", res.data.org);
                })
              }
            } else {
              alert("Unable to connect to server.")
              next(vm => vm.$router.push("/login"))
            }
          })
          .catch((err) => {
            if (err.response && err.response.status == 401)
              next(vm => vm.$router.push("/login"))
            else {
              alert("Unable to connect to server.")
              next(vm => vm.$router.push("/login"))
            }
          });
      }
      else {
          next(vm => vm.isLogined())
      }
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      },
      isLogined() {
        if(!this.loginedUser) this.$router.push('/login')
      },
      getAuditionCount () {
        this.axios.get("audtionCount").then((res) => {
          if (res.data.errcode == 0) {
            this.$store.commit('stats/setTotalData', res.data.totalDataCount || 0)
            this.$store.commit('stats/setTotalOrgs', res.data.totalOrgsCount || 0)
            this.$store.commit('stats/setTotalOrgUpdates', res.data.totalOrgUpdateCount || 0)
          }
        })
      }
    },
    mounted() {
      this.getAuditionCount()
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
</style>
