import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/Dashboard.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Users = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Users/Index.vue');
const Counters = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Stores/Counters.vue');
const CountersReport = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Reports/Counters.vue');
const Areas = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Stores/Areas.vue');
const AreasReport = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Reports/Areas.vue');
const Stores = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Stores/Stores.vue');
const StoresReport = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Reports/Stores.vue');
const OnlineStores = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Stores/OnlineStores.vue');
const OnlineStoresReport = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Reports/OnlineStores.vue');
const YearTotalReport = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Reports/YearTotal.vue');
const Songcai = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Songcai/Table.vue');

let authPages = {
    path: '/',
    component: AuthLayout,
    redirect: '/login',
    children: [
      {
        path: 'login',
        name: '登录',
        component: Login
      },
      {
        path: 'register',
        name: '注册',
        component: Register
      },
      {
        path: 'accountstatus',
        name: '账号状态',
        component: () => import(/* webpackChunkName: "account-status" */ '@/views/Pages/AccountStatus.vue')
      }
    ]
  };

const routes = [
    authPages,
    {
      path: '/',
      component: DashboardLayout,
      redirect: '/dashboard',
      name: '_Dashboard',
      children: [
        {
          path: 'dashboard',
          name: '仪表盘',
          component: Dashboard
        },
        {
          path: 'profiles',
          name: '账号资料',
          component: () => import(/* webpackChunkName: "profiles" */ '@/views/Pages/Profiles.vue')
        },
        {
          path: 'users',
          name: '账号列表',
          component: Users
        },
        {
          path: 'plans',
          name: '年度计划表',
          component: () => import(/* webpackChunkName: "plans" */ '@/views/Pages/Plans/Index.vue')
        },
        {
          path: 'counters',
          name: '专柜列表',
          component: Counters
        },
        {
          path: 'countersreport',
          name: '专柜报表',
          component: CountersReport
        },
        {
          path: 'areas',
          name: '专区列表',
          component: Areas
        },
        {
          path: 'stores',
          name: '企业列表',
          component: Stores
        },
        {
          path: 'onlinestores',
          name: '线上专馆',
          component: OnlineStores
        },
        {
          path: 'areasreport',
          name: '专区报表',
          component: AreasReport
        },
        {
          path: 'storesreport',
          name: '企业报表',
          component: StoresReport
        },
        {
          path: 'onlinestoresreport',
          name: '线上专馆报表',
          component: OnlineStoresReport
        },
        {
          path: 'totalreport',
          name: '三专统计',
          component: YearTotalReport
        },
        {
          path: 'songcai/:group',
          name: '送菜上门服务名单',
          component: Songcai
        },
        {
          path: 'datainputs',
          name: '统计表',
          component: () => import(/* webpackChunkName: "datainputs" */ '@/views/Pages/DataInputs/Index.vue'),
        },
        {
          path: 'datainputs/:id',
          name: '统计表详情',
          component: () => import(/* webpackChunkName: "datainputsdetail" */ '@/views/Pages/DataInputs/Detail.vue'),
        },
        {
          path: 'orgsaudition',
          name: '单位注册审核',
          component: () => import(/* webpackChunkName: "orgs-audition" */ '@/views/Pages/Orgs/Index.vue'),
        },
        {
          path: 'orgupdateaudition',
          name: '单位资料修改审核',
          component: () => import(/* webpackChunkName: "orgupdate-audition" */ '@/views/Pages/Orgs/UpdationIndex.vue'),
        },
        {
          path: 'dataaudition',
          name: '统计表审核',
          component: () => import(/* webpackChunkName: "orgs-audition" */ '@/views/Pages/DataInputs/AuditionList.vue'),
        },
        {
          path: 'datareport/:type',
          name: '汇总表',
          component: () => import(/* webpackChunkName: "datareport" */ '@/views/Pages/DataInputs/Aggregate.vue'),
        }
      ]
    },
    { path: '*', component: NotFound }
  ];
  
  export default routes;