// initial state
const state = () => ({
    totalData: 0,
    totalOrgs: 0,
    totalOrgUpdates: 0,
})

const getters = {
    totalData: (state) => {
        return state.totalData
    },
    totalOrgs: (state) => {
        return state.totalOrgs
    },
    totalOrgUpdates: (state) => {
        return state.totalOrgUpdates
    }
}

const actions = {
    
}

// update methods
const mutations = {
    setTotalData (state, count) {
        state.totalData = count
    },
    setTotalOrgs (state, count) {
        state.totalOrgs = count
    },
    setTotalOrgUpdates (state, count) {
        state.totalOrgUpdates = count
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
  