import { formatDate, formatDateTime } from './lib/util'
export const userMixin = {
    computed: {
        isSuper() {
            return this.$store.state.user.user && this.$store.state.user.user.is_super
        },
        isReportReader() {
            return this.$store.state.user.user && this.$store.state.user.user.is_report_reader
        },
        prefecture() {
            return this.$store.state.prefecture.prefecture && this.$store.state.prefecture.prefecture
        },
        hasMUS() { return this.has('manage_user_status'); },
        hasUSI() { return this.has('update_store_info'); },
        hasUR() { return this.has('update_reports'); },
        hasMP() { return this.has('manage_plans'); },
        deny3Z() { return this.has('deny_3zhuang') },
        hasVSC() { return this.has('view_songcai'); },
        hasESC() { return this.has('edit_songcai') && new Date().getMonth()<20; }
    },
    methods: {
        has(permission) {
            if(this.$store.state.user.user) {
                const myPermissions = this.$store.state.user.user.permissions;
                if(!myPermissions) return false;
                return myPermissions.includes(permission);
            }
            return false;
        },
        toDateString (input) {
            return formatDate(input)
        },
        toDateTimeString (input) {
            return formatDateTime(input)
        }
    }
}