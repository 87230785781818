<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center justify-content-center">
      <div class="col">
        <div class="copyright text-center text-lg-left text-muted">
            © {{year}} 广州飞橙科技
            <span class="pl-1 text-xs"> 提供技术支撑 </span>
            <span class="pl-md-1 text-xs"><i class="el-icon-phone"></i><a href="tel:18620862676">18620862676</a></span>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
